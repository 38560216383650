<template>
    <v-responsive v-if="notification">
        <v-card tile elevation="0">
            <v-card-title class="subtitle-2">
                <v-layout wrap>
                    <v-flex :xs12="$vuetify.breakpoint.mobile">{{ notification.subject }}</v-flex>
                    <v-spacer/>
                    <v-flex grow-shrink-0 class="body-2 py-1">작성일자: {{ $dayjs(notification.createdAt).format("YYYY-MM-DD") }}</v-flex>
                </v-layout>
            </v-card-title>
            <v-divider/>
            <v-card-text class="body-2">
                <p v-html="notification.content"></p>
            </v-card-text>

            <v-divider/>

            <v-card-text class="grey lighten-5">
                <v-responsive>
                    <v-layout v-if="prevNotification" align-center>
                        <v-flex shrink><v-sheet min-width="60" color="transparent"><b>이전글</b><v-icon small>{{ mdiChevronRight }}</v-icon></v-sheet></v-flex>
                        <v-flex grow shrink class="text-truncate">
                            <router-link :to="`/center/notifications/${prevNotification._id}`">{{ prevNotification.subject }}</router-link>
                        </v-flex>
                    </v-layout>
                    <v-layout v-if="nextNotification">
                        <v-flex shrink><v-sheet min-width="60" color="transparent"><b>다음글</b><v-icon small>{{ mdiChevronRight }}</v-icon></v-sheet></v-flex>
                        <v-flex grow shrink class="text-truncate">
                            <router-link :to="`/center/notifications/${nextNotification._id}`">{{ nextNotification.subject }}</router-link>
                        </v-flex>
                    </v-layout>
                </v-responsive>
            </v-card-text>
        </v-card>
    </v-responsive>
</template>

<script>
import api from "@/api";
import { mdiChevronRight } from "@mdi/js";
export default {
	components: {
	},
    data() {
        return {
            mdiChevronRight,

            notification: null,

            prevNotification: null,
            nextNotification: null
        };
    },
	mounted() {
		this.init();
	},
	methods: {
		async init() {
            try{
                var { notification, prevNotification, nextNotification } = await api.v1.center.notifications.get({ _id: this.$route.params._notification });
                this.notification = notification;
                this.prevNotification = prevNotification;
                this.nextNotification = nextNotification;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
		}
	},
    watch: {
        $route: {
            deep: true,
            handler(){
                this.init();
            }
        }
    }
}
</script>

<style scoped>
.grow { flex-grow: 1 !important; }
</style>