<template>
    <component :is="template">
        <router-notification />
    </component>
</template>
<script>
import RouterNotification from "@/components/client/center/notifications/router-notification.vue";
export default {
    components: {
        RouterNotification
    },
    computed: {
        template(){
            return () => import(`@/templates/${this.$theme.pages.center}`);
        }
    }
}
</script>

<style scoped>
.center-notification .title {
    font-size: 22px !important;
    font-weight: 600;
}
</style>