var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.routerView, {
    tag: "component"
  });

}
var staticRenderFns = []

export { render, staticRenderFns }