var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.notification ? _c('v-responsive', [_c('v-card', {
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_c('v-layout', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": _vm.$vuetify.breakpoint.mobile
    }
  }, [_vm._v(_vm._s(_vm.notification.subject))]), _c('v-spacer'), _c('v-flex', {
    staticClass: "body-2 py-1",
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_vm._v("작성일자: " + _vm._s(_vm.$dayjs(_vm.notification.createdAt).format("YYYY-MM-DD")))])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "body-2"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.notification.content)
    }
  })]), _c('v-divider'), _c('v-card-text', {
    staticClass: "grey lighten-5"
  }, [_c('v-responsive', [_vm.prevNotification ? _c('v-layout', {
    attrs: {
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "min-width": "60",
      "color": "transparent"
    }
  }, [_c('b', [_vm._v("이전글")]), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(_vm._s(_vm.mdiChevronRight))])], 1)], 1), _c('v-flex', {
    staticClass: "text-truncate",
    attrs: {
      "grow": "",
      "shrink": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": `/center/notifications/${_vm.prevNotification._id}`
    }
  }, [_vm._v(_vm._s(_vm.prevNotification.subject))])], 1)], 1) : _vm._e(), _vm.nextNotification ? _c('v-layout', [_c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "min-width": "60",
      "color": "transparent"
    }
  }, [_c('b', [_vm._v("다음글")]), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(_vm._s(_vm.mdiChevronRight))])], 1)], 1), _c('v-flex', {
    staticClass: "text-truncate",
    attrs: {
      "grow": "",
      "shrink": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": `/center/notifications/${_vm.nextNotification._id}`
    }
  }, [_vm._v(_vm._s(_vm.nextNotification.subject))])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }