<template>
    <component :is="routerView" />
</template>

<script>
import NotificationList from "./notification-list.vue";
import NotificationView from "./notification-view.vue";
export default {
    components: {
        NotificationList,
        NotificationView,
    },
    computed: {
        routerView(){
            return this.$route.params._notification ? "notification-view" : "notification-list";
        }
    }
}
</script>