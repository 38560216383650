<template>
    <v-card tile elevation="0" class="notice-table--wrap">
        <v-card tile elevation="0">
            <v-data-table :items="notifications" :headers="notificationsHeaders" mobile-breakpoint="" disable-pagination disable-sort hide-default-footer :items-per-page="-1" class="sub-board--table">
                <span slot="item.no" slot-scope="{item}">
                    <v-icon v-if="item.isNotice" size="20" color="yellow darken-2">{{ mdiAlertBoxOutline }}</v-icon>
                    <span v-else>{{ (page - 1) * limit + +notifications.filter(noti => !noti.isNotice).indexOf(item) + 1 }}</span>
                </span>
                <router-link text tile slot="item.subject" slot-scope="{item}" :to="`/center/notifications/${item._id}`">{{ item.subject }}</router-link>
                <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD") }}</span>
            </v-data-table>
        </v-card>

        <v-divider/>

        <v-card-text>
            <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)"/>
        </v-card-text>

        <v-divider />

        <v-card-text class="grey lighten-5">
            <v-layout wrap justify-center align-center class="mx-n1">
                <v-flex :xs12="$vuetify.breakpoint.mobile" :shrink="!$vuetify.breakpoint.mobile" class="pa-1"><v-select v-model="filter.searchKey" :items="searchKeyItems" item-text="text" item-value="value" outlined hide-details dense class="white" :style="{ 'maxWidth': $vuetify.breakpoint.mobile ? '100%' : '150px' }" @input="value => filter.searchValue = value ? filter.searchValue : ''"/></v-flex>
				<v-flex :xs12="$vuetify.breakpoint.mobile" :shrink="!$vuetify.breakpoint.mobile" class="pa-1">
					<v-layout class="mx-n1" align-center>
						<v-flex :shrink="!$vuetify.breakpoint.mobile" class="pa-1"><v-text-field v-model="filter.searchValue" outlined hide-details dense clearable class="white" :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true);"/></v-flex>
						<v-flex shrink class="pa-1"><v-btn color="primary" @click="page = 1; search(true)"><v-icon small class="mr-2">mdi-magnify</v-icon>검색 </v-btn></v-flex>
					</v-layout>
				</v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
import api from "@/api";
import { mdiAlertBoxOutline } from "@mdi/js";
export default {
    data(){
        return {
            mdiAlertBoxOutline,

            filter: {
                searchKey: "",
                searchValue: "",
                isNotice: false,
            },

            page: 1,
            pageCount: 0,
            limit: 10,

            notifications: [],
            notificationsHeaders: [
                { text: "no", value: "no", align: "center", width: 100 },
                { text: "제목", value: "subject", align: "center" },
                { text: "작성자", value: "writer.name", align: "center", width: 120 },
                { text: "작성일자", value: "createdAt", align: "center", width: 180 },
            ],

			searchKeyItems: [
				{ text: ":: 검색조건 ::", value: "" },
				{ text: "제목", value: "subject" },
				{ text: "내용", value: "content" },
				{ text: "제목+내용", value: "subject+content" },
				{ text: "작성자", value: "writer.name" },
				{ text: "아이디", value: "writer.username" },
			]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { notifications: statics } = await api.v1.center.notifications.gets({
                headers: { limit: 0 },
                params: { isNotice: true }
            });

            let { summary, notifications } = await api.v1.center.notifications.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });


            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.notifications = [ ...statics, ...notifications ];
        },

    },
}
</script>
<style>
    .sub-board--table thead {
        display: none;
    }

    @media (max-width:768px) {
        .sub-board--table tr {
            display: flex;
            flex-wrap: wrap;
            padding:15px 0px;
            border-bottom: thin solid rgba(0,0,0,.12);
        }
        /* .sub-board--table tr td:first-child {
            display: none;
        } */
        .sub-board--table tr td:first-child {
            border-bottom:0px !important;
            padding-bottom:5px !important;
        }
        .sub-board--table tr td:nth-child(2) {
            width:100%;
            border-bottom:0px !important;
            text-align: left !important;
            margin-bottom:8px;
        }
        .sub-board--table tr td:nth-child(2) a {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .sub-board--table tr td:nth-child(3), .sub-board--table tr td:nth-child(4) {
            border-bottom:0px !important;
            position:relative;
        }
        .sub-board--table tr td:nth-child(3)::before {
            content: "";
            display: block;
            width:1px;
            height:12px;
            background-color:#999999;
            position:absolute;
            right:0;
            top:50%;
            transform: translateY(-50%);
        }

        .sub-board--table tr td {
            height: auto !important;
        }

    }
    @media (min-width:768px) {
        .sub-board--table thead {
            display: table-header-group;
        }
        .sub-board--table tr {
            display: table-row;
        }
    }
</style>